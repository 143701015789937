import React from 'react';
import { Link } from 'react-router-dom';

const Portfolio = () => {
    const plans = [
        { weeks: "4 weeks commitment", price: 28 },
        { weeks: "12 weeks commitment", price: 27 },
        { weeks: "24+ weeks commitment", price: 26 },
      ];
    return (
        <>
            <div className="bg-[#f7f7fb] py-20 px-6" id='pricing'>
      {/* Main Heading */}
      <h2 className="text-4xl font-semibold text-center mb-12">On demand offers</h2>

      {/* Cards Section */}
      <div className="flex flex-wrap justify-center gap-12">
        {/* Card 1: Happy Deal */}
        <div className="relative bg-white rounded-lg shadow-xl w-80 transition-all ease-in-out duration-400  text-gray-700 hover:scale-105">
          {/* Header */}
          <div className="absolute -top-6 left-1/2 transform -translate-x-1/2 bg-[#186eb4] text-white text-lg font-medium text-center py-3 px-6 rounded-lg shadow-md">
            Happy Deal
          </div>
          {/* Body */}
          <div className="pt-12 pb-6 text-center">
            <p className="text-[#186eb4] text-5xl font-bold">€0</p>
            <p className="text-gray-500 text-sm">Per Hour</p>
          </div>
          <ul className="px-8 pb-6 text-gray-600 text-sm space-y-2">
            <li>• Free Sample Work (see notes for details)</li>
            <li>• Practically risk free</li>
          </ul>
        </div>

        {/* Card 2: Eco Deal */}
        <div className="relative bg-white rounded-lg shadow-xl w-80 transition-all ease-in-out duration-400   text-gray-700 hover:scale-105">
          {/* Header */}
          <div className="absolute -top-6 left-1/2 transform -translate-x-1/2 bg-[#186eb4] text-white text-lg font-medium text-center py-3 px-6 rounded-lg shadow-md">
            Eco Deal
          </div>
          {/* Body */}
          <div className="pt-12 pb-6 text-center">
            <p className="text-[#186eb4] text-5xl font-bold">€29</p>
            <p className="text-gray-500 text-sm">Per Hour</p>
          </div>
          <ul className="px-8 pb-6 text-gray-600 text-sm space-y-2">
            <li>• Applies on 20+ hours/month (rounded to quarter hours)</li>
            <li>• Fast and Flexible</li>
          </ul>
        </div>

        {/* Card 3: Flat Deal */}
        <div className="relative bg-white rounded-lg shadow-xl w-80 transition-all ease-in-out duration-400   text-gray-700 hover:scale-105">
          {/* Header */}
          <div className="absolute -top-6 left-1/2 transform -translate-x-1/2 bg-[#186eb4] text-white text-lg font-medium text-center py-3 px-6 rounded-lg shadow-md">
            Flat Deal
          </div>
          {/* Body */}
          <div className="pt-12 pb-6 text-center">
            <p className="text-[#186eb4] text-5xl font-bold">€36</p>
            <p className="text-gray-500 text-sm">Per Hour</p>
          </div>
          <ul className="px-8 pb-6 text-gray-600 text-sm space-y-2">
            <li>• Hourly Rate (rounded to half hours)</li>
            <li>• Fast and Flexible</li>
          </ul>
        </div>
      </div>

      {/* Bottom Section */}
      <h2 className="text-4xl font-semibold text-center mt-16 mb-6">
        Regular commitment offers
      </h2>
      <p className="text-gray-600 text-center max-w-2xl mx-auto text-base leading-relaxed">
        Do you need permanent assistance? A weekly commitment consists of a
        minimum of 40 working hours per week. Develop your project further with
        a professional expert, commit to your task only. We recommend our
        “Happy Deal” for risk-free testing!
      </p>
            </div>


            <div className="bg-[#f7f7fb] py-12">
      <div className="max-w-6xl mx-auto text-center">
        {/* Pricing Cards */}
        <div className="flex flex-wrap justify-center gap-8">
          {plans.map((plan, index) => (
            <div
              key={index}
              className="bg-white shadow-md rounded-lg p-6 text-center border-t-4 border-[#186eb4]"
            >
              <h3 className="text-lg font-semibold">{plan.weeks}</h3>
              <p className="text-3xl font-bold text-[#186eb4] my-4">
                € {plan.price}
              </p>
              <p className="text-gray-600">Per Hour</p>
            </div>
          ))}
        </div>

        {/* Legal Notes */}
        <div className="mt-12">
          <h4 className="text-xl font-semibold">Legal Notes</h4>
          <p className="text-gray-700 mt-4 mx-auto max-w-4xl">
            No contract, no commitment. You and we need to be on the safe side,
            even if it's about a free sample work. All work remains property of
            negoify in any case. Receipts are issued every two weeks. Once a
            reliable work relation has been established, we will switch to
            monthly billing.
          </p>
        </div>
      </div>
            </div>
        </>
    )
}

export default Portfolio;