import React, { useEffect } from 'react';
import NavBar from '../components/Navbar/NavBar';
import heroImg from '../images/hero-img.png';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles

const Hero = () => {
  // Initialize AOS
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration in milliseconds
      once: true, // Run animation only once
    });
  }, []);

  return (
    <>
      <div className="hero" id="hero">
        <div>
          <NavBar />
        </div>

        <div className="bg-[#f7f7fb] py-16 px-4 sm:px-6 lg:px-8">
          <div
            className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-2 items-center gap-8"
          >
            {/* Left Section: Text */}
            <div
              className="text-center md:text-left"
              data-aos="fade-right" // Animation for text section
            >
              <h1 className="text-4xl font-bold text-[#186eb4] mb-6">
                we tech success
              </h1>
              <p className="text-gray-700 text-lg">
                negoify Pvt. Ltd. is a digital agency specialized in providing multiple services:
                Custom Software Development for Web, Games, and Applications across multiple
                platforms, Social Media, Marketing, UI/UX, Graphics, and Corporate Identity. You
                need it? We make!
              </p>
            </div>

            {/* Right Section: Illustration */}
            <div
              className="flex justify-center"
              data-aos="fade-left" // Animation for image section
            >
              <img
                src={heroImg}
                alt="Technology Illustration"
                className="w-full max-w-lg transform hover:scale-105 transition-all duration-500"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
