import React from "react";

const solutions = [
  {
    title: "Software Development",
    icon: "fas fa-code", // Replace with the appropriate icon class
  },
  {
    title: "Web Development",
    icon: "fas fa-desktop",
  },
  {
    title: "Game Development",
    icon: "fas fa-gamepad",
  },
  {
    title: "Social Media",
    icon: "fas fa-thumbs-up",
  },
  {
    title: "Marketing",
    icon: "fas fa-chart-line",
  },
  {
    title: "UX/UI",
    icon: "fas fa-object-group",
  },
  {
    title: "Graphics",
    icon: "fas fa-paint-brush",
  },
  {
    title: "Corporate Identity",
    icon: "fas fa-globe",
  },
];

const SolutionsSection = () => {
  return (
    <div className="bg-blue-50 py-12" id="solutions">
      <div className="text-center mb-10">
        <h2 className="text-3xl font-bold text-[#186eb4]">Solutions</h2>
        <div className="border-b-2 border-blue-600 mx-auto mt-2 w-16"></div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 max-w-6xl mx-auto">
        {solutions.map((solution, index) => (
          <div
            key={index}
            className="bg-white rounded-lg shadow-md p-6 text-center hover:shadow-lg transition-shadow duration-300"
          >
            {/* Icon */}
            <div className="text-4xl text-600 mb-4">
              <i className={solution.icon}></i>
            </div>
            {/* Title */}
            <h3 className="text-lg font-medium text-gray-800">{solution.title}</h3>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SolutionsSection;
