import React from 'react';
import { HashLink } from 'react-router-hash-link';

const NavLinks = () => {
    return (
        <>
            <HashLink className="px-4 font-bold  hover:text-blue-900" smooth to="/#about">
                About Us
            </HashLink>
            <HashLink className="px-4 font-bold  hover:text-blue-900" smooth to="/#solutions">
                Solutions
            </HashLink>
            <HashLink className="px-4 font-bold  hover:text-blue-900" smooth to="/#pricing">
                Pricing
            </HashLink>
            <HashLink className="px-4 font-bold  hover:text-blue-900" smooth to="/#Clients">
                Clients
            </HashLink>
            <HashLink className="px-4 font-bold  hover:text-blue-900" smooth to="/#Team">
                Team
            </HashLink>
            <HashLink className="px-4 font-bold  hover:text-blue-900" to="/contact#contact">
                Contact Us
            </HashLink>
            
        </>
    )
}

export default NavLinks;
