import React from "react";
import kws from "../images/clients/Tinky Tunes logo.png";
import geps from "../images/clients/AHS_LOGO_2.png";
import protergia from "../images/clients/BrewOtaku-Logo-v2.png";

const logos = [kws, protergia, geps];

const Clients = () => {
  return (
    <div className="mt-8 bg-white-100 py-12" id="Clients">
      <section>
        <div className="text-center mb-10">
          <h2 className="text-3xl font-bold text-[#186eb4]">Our Clients</h2>
          <p className="text-gray-600 mt-2">Some of our clients.</p>
          <div className="border-b-2 border-[#186eb4] mx-auto mt-2 w-16"></div>
        </div>

        {/* Scrollable Client Logos */}
        <div className="overflow-hidden">
          <div className="scroll-container flex justify-center items-center">
            {/* Logos Container */}
            <div className="flex flex-wrap justify-center items-center space-x-6">
              {/* Render logos */}
              {logos.map((logo, index) => (
                <img
                  key={index}
                  src={logo}
                  alt={`client-${index}`}
                  className="h-32 w-auto object-contain mb-4"
                />
              ))}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Clients;
