import React from "react";
import Ali from '../images/staff/Ali.png';
import Shahzad from '../images/staff/Shahzad.png';
import Areeba from '../images/staff/Areeba.png';
import Usman from '../images/staff/Usman.png';
import Sidra from '../images/staff/Sidra.png';
import Zainab from '../images/staff/avatar zainab png.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faFacebook, faInstagram , faTwitter} from '@fortawesome/free-brands-svg-icons';

const TeamSection = () => {
  const teamMembers = [
    {
      name: "Ali Hamza",
      position: "CEO, Founder",
      imgUrl: Ali,
      socials: {
        linkedin: "https://www.linkedin.com/in/aalihamza/",
        facebook: "https://web.facebook.com/imalihamza48",
        instagram: "https://www.instagram.com/iammrali/",
        twitter: "#",
      },
    },
    {
      name: "Shahzad Sahaib",
      position: "CTO, Founder",
      imgUrl: Shahzad,
      socials: {
        linkedin: "https://www.linkedin.com/in/shahzad-sahaib/",
        facebook: "#",
        instagram: "#",
        twitter: "#",

      },
    },
    {
      name: "Areeba Malik",
      position: "Social Media Expert",
      imgUrl: Areeba,
      socials: {
        linkedin: "https://www.linkedin.com/in/areeba-malik-shahzad-4a8654299",
        facebook: "https://www.facebook.com/areebamalikshahzad?mibextid=ZbWKwL",
        instagram: "#",
        twitter: "#",

      },
    },
    {
      name: "Usman Khan",
      position: "Content Writer",
      imgUrl: Usman,
      socials: {
        linkedin: "#",
        facebook: "#",
        instagram: "#",
        twitter: "https://x.com/theMioKhan",
      },
    },
    {
      name: "Sidra Siddiqui",
      position: "Video Editor",
      imgUrl: Sidra,
      socials: {
        linkedin: "https://www.linkedin.com/in/sidra-siddiqui-66119b30b/",
        facebook: "#",
        instagram: "#",
        twitter: "#",

      },
    },
    {
      name: "Zainab Bibi",
      position: "Graphic Artist",
      imgUrl: Zainab,
      socials: {
        linkedin: "#",
        facebook: "#",
        instagram: "#",
        twitter: "#",

      },
    },
  ];

  return (
    <div className="bg-white py-12" id="Team">
      <div className="text-center mb-10">
        <h2 className="text-3xl font-bold text-[#186eb4]">Team</h2>
        <p className="text-gray-600 mt-2">Our team is always here to help</p>
        <div className="border-b-2 border-[#186eb4] mx-auto mt-2 w-16"></div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 max-w-6xl mx-auto">
        {teamMembers.map((member, index) => (
          <div
            key={index}
            className="group bg-blue-50 rounded-lg shadow-md p-6 text-center relative"
          >
            {/* Image Section */}
            <div className="relative">
              <img
                src={member.imgUrl}
                alt={member.name}
                className="w-24 h-24 mx-auto rounded-full"
              />
            </div>
            {/* Name and Position */}
            <h3 className="text-xl font-semibold mt-4">{member.name}</h3>
            <p className="text-gray-500 whitespace-pre-wrap">{member.position}</p>

            <div className="mt-4 flex flex-col justify-end items-center text-black">
              <div className="flex space-x-4 mb-4">
                {/* LinkedIn */}
                {member.socials.linkedin !== "#" && (
                  <a href={member.socials.linkedin} target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faLinkedin} className="text-xl" />
                  </a>
                )}
                {/* Facebook */}
                {member.socials.facebook !== "#" && (
                  <a href={member.socials.facebook} target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faFacebook} className="text-xl" />
                  </a>
                )}
                {/* Instagram */}
                {member.socials.instagram !== "#" && (
                  <a href={member.socials.instagram} target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faInstagram} className="text-xl" />
                  </a>
                )}{member.socials.twitter !== "#" && (
                  <a href={member.socials.twitter} target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faTwitter} className="text-xl" />
                  </a>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TeamSection;
